import React, { ReactElement, useContext, useEffect, useMemo, useRef, useState } from "react"
import { createPortal } from "react-dom"
import { PageProps, useStaticQuery } from "gatsby"
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"
import { Helmet } from "react-helmet"
import { NavigationQuery } from "./navigation"
import { Slices } from "./slices"
import { PrismicLink, getActiveLink, getNavDepth, getSublinks, getNavHeight } from "./prismicLink"
import { Logo } from "./svg"
import { Newsletter } from "./newsletter"
import { useMedia } from "../hooks/useMedia"
import { resizeImage } from "../utilities/resizeImage"
import { LayoutContext } from "./layout"
import linkResolver from "../utilities/linkResolver"

interface GatsbyTransition {
  state: Record<string, unknown>
  delay: number
  length: number
}

interface GatsbyTransitionLinkProps {
  current?: GatsbyTransition
  entry?: GatsbyTransition
  exit?: GatsbyTransition
  transitionStatus?: string
}

const getTransitions = ({
  current,
  navHeight,
  query,
  transitionStatus
}: GatsbyTransitionLinkProps & {
  navHeight: number
  query: boolean | string
}): {
  mainTransitionClasses: string,
  mainTransitionStyles: React.CSSProperties,
  navTransitionClasses: string,
  navTransitionStyles: React.CSSProperties,
  log: any,
} => {
  const scrollTop = typeof window !== `undefined`
      ? window?._22?.scrollTop
      : typeof document !== `undefined`
        ? {
            current: document.documentElement.scrollTop,
            exit: document.documentElement.scrollTop,
        } : {
          current: 0,
          exit: 0,
        },
    isMedium = [true, `sm`, `md`].indexOf(query) > -1,
    lastScrollTop = scrollTop?.current ?? 0,
    navVisible = isMedium
      ? true
      : transitionStatus === `exiting`
        ? lastScrollTop < navHeight
        : Math.max(scrollTop?.current, scrollTop?.exit, 0) < ((Array.isArray(current?.state?.navHeight) && current?.state?.navHeight[1]) ?? 66.39),
    animateEntry = current?.state?.navChanged || !navVisible,
    mainTransitionStyles: React.CSSProperties = {},
    navTransitionStyles: React.CSSProperties = {}

  let mainTransitionClasses = ``,
    navTransitionClasses = ``

  const log = {
    current,
    navVisible,
    animateEntry,
  }

  switch(transitionStatus) {
    case `entering`:
      mainTransitionClasses = `opacity-0 blur-lg`
      navTransitionClasses = `-translate-y-full ${animateEntry ? `motion-safe:transition-transform motion-safe:delay-500 motion-safe:duration-500` : ``}`
      break
    case `entered`:
      mainTransitionClasses = `opacity-100 blur-none`
      navTransitionClasses = `translate-y-0 ${animateEntry ? `motion-safe:transition-transform motion-safe:delay-500 motion-safe:duration-500` : ``}`
      break
    case `exiting`:
      window._22.scrollTop.exit = lastScrollTop
      mainTransitionClasses = `absolute left-0 w-full overflow-hidden opacity-0 blur-lg`
      mainTransitionStyles.top = isMedium ? `-${lastScrollTop}px` : `calc(${navHeight}px - ${lastScrollTop}px)`
      // mainTransitionStyles.height = `calc(${lastScrollTop}px + 100vh)`
      if(!navVisible) {
        // Scroll to top immediately to synchronize paints (don’t wait for Gatsby to scroll)
        document.documentElement.scrollTop = 0
        navTransitionClasses = `pointer-events-none -translate-y-full duration-0 delay-0`
      } else if(!current?.state?.navChanged) {
        navTransitionClasses = `pointer-events-none translate-y-0 duration-0 delay-0`
      } else {
        navTransitionClasses = `pointer-events-none -translate-y-full motion-safe:transition-transform motion-safe:duration-500 delay-0`
      }
      break
    case `exited`:
      mainTransitionClasses = `opacity-0 blur-lg`
      if(!navVisible) navTransitionClasses = `-translate-y-full duration-0 delay-0`
      else if(!current?.state?.navChanged) navTransitionClasses = `translate-y-0 duration-0 delay-0`
      else navTransitionClasses = `-translate-y-full motion-safe:transition-transform motion-safe:duration-500 delay-0`
      break
    default:
  }

  return {
    mainTransitionClasses,
    mainTransitionStyles,
    navTransitionClasses,
    navTransitionStyles,
    log,
  }
}

const Page = (props: PageProps<GatsbyTypes.PageByUidQuery, Record<string, never>, any> & GatsbyTransitionLinkProps): ReactElement => {
  const staticData = useStaticQuery(NavigationQuery),
    { data: { prismicSettings }, isPreview } = useMergePrismicPreviewData(staticData)

  if(!props?.data?.prismicPage?.data) return <></>

  const layoutContext = useContext(LayoutContext),
    portal = layoutContext?.navPortalRef?.current

  const { current, transitionStatus, ...rest } = props,
    query = useMedia([`(max-width: 639px)`, `(max-width: 767px)`, `(max-width: 1023px)`, `(max-width: 1279px)`, `(max-width: 1535px)`], [true, `sm`, `md`, `lg`, `xl`], `2xl`),
    isMobile = [true].indexOf(query) > -1,
    isSmall = [true, `sm`].indexOf(query) > -1,
    isMedium = [true, `sm`, `md`].indexOf(query) > -1,
    links = prismicSettings?.data?.primary_nav?.document?.data?.links,
    pathname = props.location.pathname,
    activeLink = getActiveLink(pathname, links),
    activeLinkURL = linkResolver(Object.assign({}, activeLink?.target, activeLink?.target?.document)),
    activeLinkTitle = activeLink?.target?.document?.data?.title,
    sublinks = getSublinks(activeLink),
    activeSublink = getActiveLink(pathname, sublinks),
    navDepth = getNavDepth(activeLink),
    navHeight = getNavHeight(navDepth)[isMedium ? 0 : 1],
    // Memoize transition calculation to prevent change due to unrelated re-render
    {
      mainTransitionClasses,
      mainTransitionStyles,
      navTransitionClasses,
      navTransitionStyles,
      log,
    } = useMemo(() =>
      getTransitions({
        current,
        navHeight,
        query,
        transitionStatus
      }),
      [current, transitionStatus]
    ),
    showSubscribeFooter = true,
    mobileNavPaddingRef = useRef<HTMLDivElement>(null)

  // transitionStatus === `entering` && console.log(`${pathname} ${transitionStatus}`, log?.current?.state?.navChanged, log, navTransitionClasses)
  // transitionStatus === `entered` && console.log(`${pathname} ${transitionStatus}`, log?.current?.state?.navChanged, log, navTransitionClasses)

  useEffect(() => {
    const navBackground = document.querySelector(`#nav-bg`)
    if(navBackground) (navBackground as HTMLElement).style.height = `${navHeight}px`
    if(mobileNavPaddingRef?.current) mobileNavPaddingRef.current.style.height = `${navHeight}px`
  }, [])

  // SEO and metadata
  const page = props?.data?.prismicPage?.data,
    title = page?.title
      ? page.title
      : undefined,
    isIndex = pathname === `/`,
    firstTextSlice = page?.body?.find(slice => [`text`, `split`].indexOf(slice.slice_type) > -1),
    firstTextSliceContent = firstTextSlice?.primary?.text?.text?.replace(/\s+/g, ` `),
    description = page?.description
      ? page.description
      : firstTextSliceContent
        ? firstTextSliceContent
        : undefined,
    resizedFeaturedImage = page?.image?.gatsbyImageData ? resizeImage(page.image?.gatsbyImageData) : {}

  const [hover, setHover] = useState(false),
    hoverOn = () => setHover(true),
    hoverOff = () => setHover(false),
    [focus, setFocus] = useState(false),
    focusOn = () => setFocus(true),
    focusOff = () => setFocus(false)

  const navigation = (
    <div className={`z-20 top-0 left-0 right-0 fixed lg:static ${navDepth > 1 ? `pb-[18px]` : `pb-2.5`} flex flex-col justify-center items-center pt-4 transform ${navTransitionClasses} will-change-[contents,transform] border-b border-b-transparent`}>
      {sublinks.length ? (
        <>
          <PrismicLink
            className="group relative"
            link={!isIndex && pathname === activeLinkURL
              ? Object.assign({}, links[0].target, links[0].target?.document) 
              : Object.assign({}, activeLink?.target, activeLink?.target?.document)
            }
          >
            <h2
              className={`font-serif font-medium text-[42px] leading-[1.2] text-center ${!isIndex && pathname === activeLinkURL ? `opacity-100 mouse:group-hover:opacity-0 motion-safe:mouse:transition-opacity motion-safe:mouse:duration-300` : ``}`}
              dangerouslySetInnerHTML={{ __html: activeLinkTitle.replace(`&`, `<em>&amp;</em>`) }}
            />
            {!isIndex && pathname === activeLinkURL && (
              <h2 className="absolute top-0 left-1/2 transform -translate-x-1/2 font-serif font-medium text-[42px] leading-[1.2] text-center h-[50.391px] pt-px opacity-0 mouse:group-hover:opacity-100 motion-safe:mouse:transition-opacity motion-safe:mouse:duration-300">
                <Logo className="w-36" />
              </h2>
            )}
          </PrismicLink>
          <div className={`relative w-max mt-2 mx-auto flex justify-center ${navDepth > 2 ? `h-[42.376px] lg:h-auto` : ``}`}>
            {sublinks?.map((sublink, i) => {
              const { subnav, target, title } = sublink,
                sublinkURL = linkResolver(Object.assign({}, target, target?.document)),
                followLink = sublinkURL !== activeLinkURL,
                content = (
                  <>
                    {isMobile ? title.replace(`Manor `, ``) : title}
                    {subnav?.document?.data?.links && (
                      <div
                        className={`absolute top-full left-1/2 transform -translate-x-1/2 -translate-y-full lg:translate-y-0 w-max pt-4 flex justify-center ${sublink === activeSublink && !hover && !focus ? `opacity-100` : `opacity-0`} lg:opacity-0 ${sublink === activeSublink && !hover && !focus ? `pointer-events-auto` : `pointer-events-none`} lg:pointer-events-none group-hover:opacity-100 group-focus-visible:opacity-100 focus-within:opacity-100 transition-opacity duration-300 group-hover:pointer-events-auto`}
                      >
                        {subnav?.document?.data?.links?.map(({ target, title }) => {
                          const active = linkResolver(Object.assign({}, target, target?.document)) === pathname
                          return (
                            <PrismicLink
                              className={`mx-2 sm:mx-3.5 text-subnav uppercase ${active ? `text-red` : `text-black`} lg:text-black mouse:hover:text-red mouse:focus-visible:text-red transition-color duration-300`}
                              link={Object.assign({}, target, target?.document)}
                              key={title}
                              onClick={() => !isMedium && (document.activeElement as HTMLElement).blur()}
                            >
                              {isSmall ? title.replace(` Suite`, ``) : title}
                            </PrismicLink>
                          )
                        })}
                      </div>
                    )}
                  </>
                )
              return followLink ? (
                <PrismicLink
                  className="group mx-2 sm:mx-3.5 text-subnav uppercase mouse:hover:text-red mouse:focus-visible:text-red transition-color duration-300"
                  link={Object.assign({}, target, target?.document)}
                  key={title}
                  onMouseEnter={hoverOn}
                  onMouseLeave={hoverOff}
                  onFocus={focusOn}
                  onBlur={focusOff}
                >
                  {content}
                </PrismicLink>
              ) : (
                <div
                  className="cursor-default group mx-2 sm:mx-3.5 text-subnav uppercase mouse:hover:text-red mouse:focus-visible:text-red transition-color duration-300"
                  key={title}
                  onMouseEnter={hoverOn}
                  onMouseLeave={hoverOff}
                  onFocus={focusOn}
                  onBlur={focusOff}
                  tabIndex={-1}
                >
                  {content}
                </div>
              )
            })}
          </div>
        </>
      ) : (
        <PrismicLink
          className="group relative font-serif mouse:hover:text-red mouse:focus-visible:text-red transition-colors duration-300"
          // link={{
          //   data: {
          //     title: page?.title ?? null,
          //     parent: {
          //       type: page?.parent?.type ?? null,
          //       uid: page?.parent?.uid ?? null,
          //     },
          //   },
          //   link_type: `Document`,
          //   title: page?.title,
          //   type: `page`,
          //   uid: props?.data?.prismicPage?.uid,
          //   url: pathname,
          // }}
          link={Object.assign({}, links[0].target, links[0].target?.document)}
        >
          {!isIndex && props?.data?.prismicPage?.data?.title ? (
            <h2 className="font-serif font-medium text-[42px] leading-[1.2] text-center opacity-100 mouse:group-hover:opacity-0 motion-safe:mouse:transition-opacity motion-safe:mouse:duration-300">
              {title}
            </h2>
          ) : (
            <h2 className="font-serif font-medium text-[42px] leading-[1.2] text-center h-[50.391px] pt-px">
              <Logo className="w-36" />
            </h2>
          )}
          {!isIndex && props?.data?.prismicPage?.data?.title && (
            <span className="absolute top-0 left-1/2 transform -translate-x-1/2 font-serif font-medium text-[42px] leading-[1.2] text-center h-[50.391px] pt-px opacity-0 mouse:group-hover:opacity-100 motion-safe:mouse:transition-opacity motion-safe:mouse:duration-300">
              <Logo className="w-36" />
            </span>
          )}
        </PrismicLink>
      )}
    </div>
  )

  return (
    <>
      <Helmet>
        {/* URL */}
        <link rel="canonical" href={`https://the22.london${pathname}`} />
        <meta property="og:url" content={`https://the22.london${pathname}`} />
        {/* Title */}
        {title && <title>{title}{!isIndex ? ` | The Twenty Two` : ``}</title>}
        {title && <meta property="og:title" content={`${title}${!isIndex ? ` | The Twenty Two` : ``}`} />}
        {title && <meta name="twitter:title" content={`${title}${!isIndex ? ` | The Twenty Two` : ``}`} />}
        {/* Description */}
        {description && <meta name="description" content={description} />}
        {description && <meta property="og:description" content={description} />}
        {description && <meta name="twitter:description" content={description} />}
        {/* Image */}
        {resizedFeaturedImage?.src !== `` && <meta name="twitter:image" content={resizedFeaturedImage.src} />}
        {resizedFeaturedImage?.src !== `` && <meta property="og:image" content={resizedFeaturedImage.src} />}
        {resizedFeaturedImage?.width && <meta property="og:image:width" content={`${resizedFeaturedImage.width}`} />}
        {resizedFeaturedImage?.height && <meta property="og:image:height" content={`${resizedFeaturedImage.height}`} />}
      </Helmet>
      {portal && createPortal(navigation, portal)}
      <div className="hidden lg:block">
        {navigation}
      </div>
      <div
        className={`transform ${mainTransitionClasses} motion-safe:transition-[filter,opacity] motion-safe:duration-700 motion-safe:will-change-[filter,opacity,position,top]`}
        style={mainTransitionStyles}
      >
        <div
          className="lg:hidden"
          ref={mobileNavPaddingRef}
          style={{
            height: `${navHeight}px`,
          }}
        />
        <Slices body={page?.body} />
        {showSubscribeFooter ? (
          <div className="pointer-events-none flex justify-center lg:hidden">
            <div className="w-full sm:w-max sm:mb-100 text-center bg-sand sm:bg-none">
              <div className="inline-block mx-auto">
                <Newsletter className="pr-5" hasRef={false} />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  )
}

export default Page
export { Page }