import React, { ReactElement } from "react"
import { PrismicLink } from "./prismicLink"
import { PrismicRichText } from "@prismicio/react"

const Text = ({ slice, ...rest }: any): ReactElement => {
  return (
    <div className="slice text w-screen max-w-[600px] mx-auto px-4 md:px-0" data-length={slice?.primary?.text?.richText?.length ?? 0}>
      <PrismicRichText
        field={slice?.primary?.text?.richText}
      />
      <div className={`${slice?.primary?.cta_link && slice?.primary?.cta_text ? `block` : `hidden`} link pt-4`}>
        <PrismicLink
          className="inline-block w-max mx-auto px-10 py-2.5 border border-black text-xs text-center uppercase mouse:hover:bg-sand mouse:focus-visible:bg-sand mouse:motion-safe:transition-colors mouse:motion-safe:duration-300"
          style={{ color: `#000`, }}
          link={slice?.primary?.cta_link}
          >
          {slice?.primary?.cta_text}
        </PrismicLink>
      </div>
    </div>
  )
}

export default Text
export { Text }