import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { ReactElement } from "react"
import { PrismicRichText } from "@prismicio/react"
import { PrismicLink } from "./prismicLink"

const Split = ({ slice, ...rest }: any): ReactElement => {
  const image = getImage(slice?.primary?.image?.gatsbyImageData)
  return (
    <div className="mx-auto slice split flex flex-col lg:flex-row justify-center items-center px-5 lg:px-0">
      <div className={`${slice?.primary?.alignment ? `` : `lg:order-2`} w-full max-w-[400px] lg:h-500 flex flex-col items-center justify-center text-center`}>
        <div className="text">
          <PrismicRichText
            field={slice?.primary?.text?.richText}
          />
        </div>
        <PrismicLink
          className="hidden lg:inline mt-8 px-10 py-2.5 border border-black text-xs uppercase mouse:hover:bg-sand mouse:focus-visible:bg-sand mouse:motion-safe:transition-colors mouse:motion-safe:duration-300"
          link={Object.assign({}, slice?.primary?.cta_link, slice?.primary?.cta_link?.document)}
        >
          {slice?.primary?.cta_text}
        </PrismicLink>
      </div>
      <div className={`${slice?.primary?.alignment ? `` : `lg:order-1`} block w-5 h-8`} />
      {image ? (
        <GatsbyImage
          alt={slice?.primary?.image?.alt ?? ``}
          // className="w-full lg:w-400 max-w-350 lg:max-w-none aspect-7/8 lg:aspect-4/5"
          className="w-350 h-400 lg:w-400 lg:h-500"
          image={image}
          sizes="(min-width: 1024px) 400px, (min-width: 380px) 350px, calc(100vw - 40px)"
        />
      ) : (
        // <div className="w-full lg:w-400 max-w-350 lg:max-w-none aspect-7/8 lg:aspect-4/5 bg-gray-light" />
        <div className="w-350 h-400 lg:w-400 lg:h-500 bg-gray-light" />
      )}
      <PrismicLink
        className="inline lg:hidden mt-4 px-10 py-2.5 border border-black text-xs text-center uppercase mouse:hover:bg-sand mouse:focus-visible:bg-sand mouse:motion-safe:transition-colors mouse:motion-safe:duration-300"
        link={Object.assign({}, slice?.primary?.cta_link, slice?.primary?.cta_link?.document)}
      >
        {slice?.primary?.cta_text}
      </PrismicLink>
    </div>
  )
}

export default Split
export { Split }