import { LinkResolverFunction } from "@prismicio/helpers"

const parentLinkResolver = (doc) => {
  if(!doc.data) return ``
  if(!doc.data.parent) return ``
  return linkResolver(doc.data.parent?.document ?? doc.data.parent)
}

const linkResolver: LinkResolverFunction = (doc) => {
  switch (doc.type) {
    case "page":
      return doc.uid !== `index` ? `${parentLinkResolver(doc)}/${doc.uid}` : `/`
    case "settings":
      return `/`
    case "navigation":
      return `/`
    default:
      return ``
  }
}

export default linkResolver
export { linkResolver }