import { isHTMLElement } from "./isHTMLElement"

const serializeForm = (element: unknown): { [key: string]: string } => {
	const data: { [key: string]: string } = {}

  if(isHTMLElement(element) && element.tagName === `FORM`) {
    const formData = new FormData(element as HTMLFormElement)
    for (const key of formData.keys()) {
      data[key] = `${formData.get(key)}`
    }
  }

	return data
}

export default serializeForm
export { serializeForm }