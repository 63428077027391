/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import * as React from "react"
import * as prismic from "@prismicio/client"
import { PrismicProvider } from "@prismicio/react"
import {
  componentResolverFromMap,
  PrismicPreviewProvider,
} from "gatsby-plugin-prismic-previews"
import { linkResolver } from "./src/utilities/linkResolver"
import { Page } from "./src/components/page"
import { PrismicLink } from "./src/components/prismicLink"
import "./src/css/index.css"

export const wrapRootElement = ({ element }) => {
  const endpoint = prismic.getEndpoint(process.env.GATSBY_REPOSITORY_NAME),
    client = prismic.createClient(endpoint)

  return (
    <PrismicPreviewProvider
      repositoryConfigs={[
        {
          accessToken: process.env.PRISMIC_TOKEN,
          linkResolver,
          repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY,
          componentResolver: componentResolverFromMap({
            page: Page,
          }),
        },
      ]}
    >
      <PrismicProvider
        client={client}
        linkResolver={linkResolver}
        richTextComponents={{
          heading1: ({ children }) => <h1 className="text-5xl">{children}</h1>,
          heading2: ({ children }) => <h2 className="text-4xl">{children}</h2>,
          heading3: ({ children }) => <h3 className="text-3xl">{children}</h3>,
          heading4: ({ children }) => <h4 className="-text-lg sm:text-lg">{children}</h4>,
          heading5: ({ children }) => <h5 className="-text-sm sm:text-sm">{children}</h5>,
          heading6: ({ children }) => <h6 className="text-xs">{children}</h6>,
          hyperlink: ({ children, node: { data } }) => <PrismicLink link={data}>{children}</PrismicLink>,
          paragraph: ({ children }) => <p className="-text-base sm:text-base">{children}</p>,
        }}
      >
        {element}
      </PrismicProvider>
    </PrismicPreviewProvider>
  )
}