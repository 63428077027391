import React, { useEffect, useState, useRef } from "react"
import { Sidebar } from "./sidebar"
import { useJSONCookie } from "../hooks/useCookie"
import Cookies from "js-cookie"
import useEventListener from "../hooks/useEventListener"
import { Helmet } from "react-helmet"

declare global {
  interface Window {
    [key: string]: any
  }
}

const sendPageview = () => {
  window[`ga-disable-${process.env.GATSBY_GOOGLE_GTAG_ID}`] = false
  if (typeof window.ga === `function`) window.ga(`send`, `pageview`)

  if(typeof window.dataLayer?.push === `function`) window.dataLayer.push({ event: `gdpr_accepted` })

  if(typeof window.fbq === `function`) {
    window.fbq(`consent`, `grant`)
    window.fbq(`track`, `PageView`)
  }
}

const GDPR = () => {
  const [gdpr] = useJSONCookie(`gdpr`, true),
    [gdprHeight, setGdprHeight] = useState(0),
    gdprRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if(gdprRef?.current?.offsetHeight !== gdprHeight) setGdprHeight(gdprRef?.current?.offsetHeight ?? 0)
  }, [])

  // When GDPR cookie changes
  useEffect(() => {
    // Update ga-disable immediately
    window[`ga-disable-${process.env.GATSBY_GOOGLE_GTAG_ID}`] = gdpr

    // Grant fb consent
    if(typeof window.fbq === `function`) window.fbq(`consent`, gdpr ? `revoke` : `grant`)

    // If the banner has been dismissed, do nothing
    if (!gdpr) return

    // If the banner is not dismissed, clear any existing cookies
    const cookies = Cookies.get(),
      cookieNames = Object.keys(cookies)
    cookieNames.forEach((name) => Cookies.remove(name))
  }, [gdpr])

  useEventListener(`resize`, () => {
    if(!gdpr || !open) return
    if(gdprRef?.current?.offsetHeight !== gdprHeight) setGdprHeight(gdprRef?.current?.offsetHeight ?? 0)
  }, typeof window === `undefined` ? {} : window, { passive: true })

  return (
    <>
      <Helmet>
        <script>{`
          window["ga-disable-${process.env.GATSBY_GOOGLE_GTAG_ID}"] = ${gdpr};
          fbq('consent', '${gdpr ? `revoke` : `grant`}');
        `}</script>
      </Helmet>
      <Sidebar
        openClassNames="transition-opacity opacity-100 duration-300"
        closedClassNames="transition-opacity opacity-0 duration-300"
        id="gdpr"
        x={false}
      >
        {(close, open) => (
          <div
            className="flex text-xs"
            ref={gdprRef}
          >
            {open ? (
              <style>{`
                body {
                  padding-bottom: ${(gdprRef?.current?.offsetHeight ?? 0) + 20}px;
                }
              `}</style>
            ) : null}
            <p className="flex-initial ml-2 my-0 text-xs uppercase">Cookie policy</p>
            <p className="flex-initial mx-2 my-0 text-xs">Our site uses cookies to provide the best experience. Do you consent to the use of cookies?</p>
            <div className="flex-initial ml-auto text-right">
              <button
                className="mr-2 text-xs uppercase mouse:hover:text-red mouse:focus-visible:text-red transition-colors duration-300"
                onClick={() => {
                  close({ expires: 365 })
                  sendPageview()
                }}
              >
                Accept
              </button>
              <button
                className="mr-2 text-xs uppercase mouse:hover:text-red mouse:focus-visible:text-red transition-colors duration-300"
                onClick={close}
              >
                Decline
              </button>
            </div>
          </div>
        )}
      </Sidebar>
    </>
  )
}

export default GDPR
export { GDPR }