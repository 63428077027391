import React, { useContext, useEffect, useState } from "react"
import { serializeForm } from "../utilities/serializeForm"
import { LayoutContext } from "./layout"

type subscriptionStatus = `FAILURE` | `SUCCESS` | undefined

const Newsletter = (props: any) => {
  const layoutContext = useContext(LayoutContext),
    active = layoutContext.subscribeBannerState,
    toggleActive = () => layoutContext.setSubscribeBannerState(!active),
    [subscriptionStatus, setSubscriptionStatus] = useState<subscriptionStatus>(undefined),
    handleSubmit = async (e: FormEvent) => {
      e.preventDefault()
      const response = await fetch(
          `/.netlify/functions/subscribe`, {
            method: `POST`,
            cache: `no-cache`,
            headers: {
              "Content-Type": `application/json`
            },
            body: JSON.stringify(serializeForm(e.target))
          })

      if(response.ok) setSubscriptionStatus(`SUCCESS`)
      else setSubscriptionStatus(`FAILURE`)
    },
    transform = props?.transform ?? false

  return (
    <div className={`pointer-events-auto flex items-stretch pl-0 ml-auto bg-sand ${transform ? `relative left-[-65px] transform ${active ? `translate-x-[65px]` : `translate-x-full`}` : ``} transition-transform duration-300 will-change-transform ${props?.className}`}>
      <button
        className={`${transform ? `cursor-pointer` : `cursor-default`} w-[30px] h-[93px] box-content pl-4 pr-5 py-6 font-serif font-medium text-[30px] leading-none text-red italic`}
        onClick={toggleActive}
        ref={props.hasRef ? layoutContext.subscribeBannerButtonRef : null}
        tabIndex={transform ? 0 : -1}
      >
        <span
          style={{
            writingMode: `vertical-lr`,
            textOrientation: `mixed`,
          }}
        >
          Stay <span className="not-italic">in</span> Touch
        </span>
      </button>
      <form
        className="inline-flex flex-col justify-between h-[141px] py-6"
        onSubmit={handleSubmit}
      >
        <label htmlFor="FirstNameInput" className="text-xs uppercase mr-6">Sign up to receive news from The Twenty Two.</label>
        {subscriptionStatus === `SUCCESS` ? (
          <span className="text-xs text-center uppercase mr-6" role="alert">Subscription successful.</span>
        ) : subscriptionStatus === `FAILURE` ? (
          <span className="text-xs text-center uppercase text-red mr-6" role="alert">Failed to subscribe, please try again.</span>
        ) : null}
        <div className="inline-flex items-end">
          <div className="inline-flex flex-col flex-grow">
            <input
              className="appearance-none py-0.5 text-xs uppercase placeholder-[#858585] border-b border-[#858585] bg-transparent rounded-none"
              id="FirstNameInput"
              name="firstname"
              onChange={subscriptionStatus === `FAILURE` ? () => setSubscriptionStatus(undefined) : undefined}
              placeholder="First name"
              required={true}
              type="text"
            />
            <input
              className="appearance-none py-0.5 text-xs uppercase placeholder-[#858585] border-b border-[#858585] bg-transparent rounded-none"
              id="LastNameInput"
              name="lastname"
              onChange={subscriptionStatus === `FAILURE` ? () => setSubscriptionStatus(undefined) : undefined}
              placeholder="Last name"
              required={false}
              type="text"
            />
            <input
              className="appearance-none py-0.5 text-xs uppercase placeholder-[#858585] border-b border-[#858585] bg-transparent rounded-none"
              id="EmailInput"
              name="email"
              onChange={subscriptionStatus === `FAILURE` ? () => setSubscriptionStatus(undefined) : undefined}
              placeholder="Email address"
              required={true}
              type="email"
            />
          </div>
          <input className="hidden" type="text" name="opt_in_email" value="Y" readOnly />
          <input
            className="appearance-none cursor-pointer pt-[13px] pb-2 px-5 font-serif font-medium text-[30px] leading-none text-white bg-black rounded-none"
            type="submit"
            value="Subscribe"
          />
        </div>
      </form>
    </div>
  )
}

export default Newsletter
export { Newsletter }