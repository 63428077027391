import React, { useEffect, useRef, useState } from "react"
import {
  graphql,
  useStaticQuery
} from "gatsby"
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { PrismicLink } from "./prismicLink"
import { Club } from "./svg"
import { useEventListener } from "../hooks/useEventListener"
import { isHTMLElement } from "../utilities/isHTMLElement"
import { linkResolver } from "../utilities/linkResolver"
import "focus-visible/dist/focus-visible.min.js"
import "wicg-inert"

const NavigationQuery = graphql`
  fragment TargetDocumentFragment on PrismicAllDocumentTypes {
    ... on PrismicPage {
      data {
        title
        parent {
          type
          uid
        }
      }
    }
  }

  fragment LinkFragment on PrismicLinkType {
    link_type
    target
    type
    uid
    url
  }

  fragment LinksFragment on PrismicNavigationDataLinks {
    title
    target {
      document {
        ...TargetDocumentFragment
      }
      ...LinkFragment
    }
  }

  fragment SubnavFragment on PrismicLinkType {
    document {
      ... on PrismicNavigation {
        data {
          links {
            ...LinksFragment
          }
        }
      }
    }
    ...LinkFragment
  }

  query NavigationQuery {
    prismicSettings {
      data {
        primary_nav {
          document {
            ... on PrismicNavigation {
              data {
                links {
                  ...LinksFragment
                  subnav {
                    document {
                      ... on PrismicNavigation {
                        id
                        data {
                          links {
                            ...LinksFragment
                            subnav {
                              ...SubnavFragment
                            }
                          }
                        }
                        _previewable
                      }
                    }
                    ...LinkFragment
                  }
                  image {
                    gatsbyImageData(width: 124, height: 143)
                    alt
                    dimensions {
                      height
                      width
                    }
                  }
                }
              }
              _previewable
            }
          }
        }
        secondary_nav {
          document {
            ... on PrismicNavigation {
              id
              data {
                links {
                  ...LinksFragment
                  subnav {
                    ...SubnavFragment
                  }
                }
              }
              _previewable
            }
          }
        }
      }
      _previewable
    }
  }
`

const Navigation = (props: any) => {
  const staticData = useStaticQuery(NavigationQuery),
    { data: { prismicSettings }, isPreview } = useMergePrismicPreviewData(staticData),
    links = prismicSettings?.data?.primary_nav?.document?.data?.links,
    linkURLs = links.map(({ image, subnav, target, title }, i) => linkResolver(Object.assign({}, target, target?.document))),
    pathURL = `/${props?.path?.split(`/`)[1]}`,
    pathHasNavParent = linkURLs.indexOf(pathURL) > -1,
    secondaryLinks = prismicSettings?.data?.secondary_nav?.document?.data?.links,
    navRef = useRef<HTMLElement>(null),
    [open, setOpen] = useState(false),
    toggleOpen = () => setOpen(!open),
    [hover, setHover] = useState(false),
    hoverOn = () => setHover(true),
    hoverOff = () => setHover(false),
    [focus, setFocus] = useState(false),
    focusOn = () => setFocus(true),
    focusOff = () => setFocus(false),
    [reservations, setReservations] = useState(false),
    toggleReservations = () => setReservations(!reservations)

  useEventListener(`click`, (e) => {
    if(!open) return
    if(!navRef.current) return
    if(navRef.current === e.target || navRef.current.contains(e.target)) return
    if(e.target && isHTMLElement(e.target) && e.target.hasAttribute(`data-toggle`)) return
    setOpen(false)
  })

  useEffect(() => {
    if(open && navRef.current) navRef.current.focus()
  }, [open])

  return (
    <>
      <div
        className="z-40 pointer-events-none fixed top-0 left-0 w-screen h-[72px] flex items-center lg:items-start justify-between p-4"
      >
        {/* Hamburger */}
        <button className="pointer-events-auto" data-toggle onClick={toggleOpen}>
          <span className="sr-only">{open ? `Close` : `Open`} menu</span>
          <svg aria-hidden="true" className="pointer-events-none stroke-current" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.45">
              <path d="M1 2H19" />
              <path d="M1 10H19" />
              <path d="M1 18H19" />
            </g>
          </svg>
        </button>
        {/* Desktop bell */}
        <div className="group hidden lg:flex flex-col items-end" tabIndex={-1}>
          <button className="relative cursor-default pointer-events-auto flex flex-col items-center justify-center w-11 h-11 -mt-0.5 text-red" tabIndex={0}>
            <div className="absolute -z-[1] inset-0 rounded-full bg-sand"></div>
            <svg aria-hidden="true" className="-mt-1 mr-px fill-current" height="20" viewBox="20 23 56 56" width="20" xmlns="http://www.w3.org/2000/svg">
              <path d="m69.842 60.949c-.828 0-1.5-.671-1.5-1.5 0-10.114-8.229-18.342-18.342-18.342s-18.342 8.228-18.342 18.342c0 .829-.672 1.5-1.5 1.5s-1.5-.671-1.5-1.5c0-11.768 9.574-21.342 21.342-21.342s21.342 9.574 21.342 21.342c0 .828-.672 1.5-1.5 1.5z" />
              <path d="m50 38.902c-.828 0-1.5-.671-1.5-1.5v-6.299c0-.829.672-1.5 1.5-1.5s1.5.671 1.5 1.5v6.299c0 .828-.672 1.5-1.5 1.5z" />
              <path d="m56.3 32.603h-12.599c-.828 0-1.5-.671-1.5-1.5s.672-1.5 1.5-1.5h12.599c.828 0 1.5.671 1.5 1.5s-.672 1.5-1.5 1.5z" />
              <path d="m75.196 70.397h-50.392c-.828 0-1.5-.671-1.5-1.5 0-4.066 1.443-10.949 6.854-10.949h39.684c5.411 0 6.854 6.882 6.854 10.949 0 .829-.672 1.5-1.5 1.5zm-48.786-3h47.187c-.239-2.305-1.046-6.449-3.755-6.449h-39.684c-2.678.001-3.499 4.146-3.748 6.449z" />
            </svg>
            <span className="text-xs uppercase">Book</span>
          </button>
          <div className="pointer-events-none group-hover:pointer-events-auto group-focus-within:pointer-events-auto group-focus-visible:pointer-events-auto opacity-0 group-hover:opacity-100 group-focus-within:opacity-100 group-focus-visible:opacity-100 hidden lg:flex flex-col items-end pt-1 text-xs uppercase transition-opacity duration-300">
            <PrismicLink
              className="py-0.5 mouse:hover:text-red mouse:focus-visible:text-red transition-colors duration-300"
              link={{
                link_type: `Web`,
                url: `https://www.sevenrooms.com/reservations/thetwentytwo`,
              }}
              onClick={() => (document?.activeElement as HTMLElement)?.blur()}
            >
              Book a table
            </PrismicLink>
            <PrismicLink
              className="py-0.5 mouse:hover:text-red mouse:focus-visible:text-red transition-colors duration-300"
              link={{
                link_type: `Web`,
                url: `https://bookings.the22.london/114630`,
              }}
              onClick={() => (document?.activeElement as HTMLElement)?.blur()}
            >
              Book a room
            </PrismicLink>
            <PrismicLink
              className="py-0.5 mouse:hover:text-red mouse:focus-visible:text-red transition-colors duration-300"
              link={{
                link_type: `Web`,
                url: `/book-an-event`,
              }}
              onClick={() => (document?.activeElement as HTMLElement)?.blur()}
            >
              Book an event
            </PrismicLink>
          </div>
        </div>
        {/* Mobile bell */}
        <button className="pointer-events-auto flex flex-col items-center justify-center bg-sand text-red rounded-full w-11 h-11 lg:hidden" onClick={toggleReservations}>
          <svg aria-hidden="true" className="-mt-px mr-px fill-current" height="20" viewBox="20 23 56 56" width="20" xmlns="http://www.w3.org/2000/svg">
            <path d="m69.842 60.949c-.828 0-1.5-.671-1.5-1.5 0-10.114-8.229-18.342-18.342-18.342s-18.342 8.228-18.342 18.342c0 .829-.672 1.5-1.5 1.5s-1.5-.671-1.5-1.5c0-11.768 9.574-21.342 21.342-21.342s21.342 9.574 21.342 21.342c0 .828-.672 1.5-1.5 1.5z" />
            <path d="m50 38.902c-.828 0-1.5-.671-1.5-1.5v-6.299c0-.829.672-1.5 1.5-1.5s1.5.671 1.5 1.5v6.299c0 .828-.672 1.5-1.5 1.5z" />
            <path d="m56.3 32.603h-12.599c-.828 0-1.5-.671-1.5-1.5s.672-1.5 1.5-1.5h12.599c.828 0 1.5.671 1.5 1.5s-.672 1.5-1.5 1.5z" />
            <path d="m75.196 70.397h-50.392c-.828 0-1.5-.671-1.5-1.5 0-4.066 1.443-10.949 6.854-10.949h39.684c5.411 0 6.854 6.882 6.854 10.949 0 .829-.672 1.5-1.5 1.5zm-48.786-3h47.187c-.239-2.305-1.046-6.449-3.755-6.449h-39.684c-2.678.001-3.499 4.146-3.748 6.449z" />
          </svg>
          <span className="text-xs uppercase">Book</span>
        </button>
        <div
          className={`lg:hidden z-100 fixed inset-0 w-screen h-screen p-12 flex justify-center items-center bg-black bg-opacity-30 ${reservations ? `opacity-100 pointer-events-auto` : `opacity-0 pointer-events-none`} motion-safe:transition-opacity motion-safe:duration-500`}
          onClick={toggleReservations}
        >
          <div className="w-full max-w-xs h-auto py-12 flex flex-col justify-center items-center text-xs uppercase bg-sand" onClick={(e) => e.stopPropagation()}>
            <span className="font-serif font-medium text-2xl normal-case text-red">Reservations</span>
            <PrismicLink
              className="inline-block w-48 mt-4 px-10 py-2.5 border border-black text-xs text-center uppercase mouse:hover:text-white mouse:focus-visible:text-white mouse:hover:bg-red mouse:focus-visible:bg-red transition-colors duration-300"
              link={{
                link_type: `Web`,
                url: `https://www.sevenrooms.com/reservations/thetwentytwo`,
              }}
              onClick={toggleReservations}
            >
              Book a table
            </PrismicLink>
            <PrismicLink
              className="inline-block w-48 mt-4 px-10 py-2.5 border border-black text-xs text-center uppercase mouse:hover:text-white mouse:focus-visible:text-white mouse:hover:bg-red mouse:focus-visible:bg-red transition-colors duration-300"
              link={{
                link_type: `Web`,
                url: `https://bookings.the22.london/114630`,
              }}
              onClick={toggleReservations}
            >
              Book a room
            </PrismicLink>
            <PrismicLink
              className="inline-block w-48 mt-4 px-10 py-2.5 border border-black text-xs text-center uppercase mouse:hover:text-white mouse:focus-visible:text-white mouse:hover:bg-red mouse:focus-visible:bg-red transition-colors duration-300"
              link={{
                link_type: `Web`,
                url: `/book-an-event`,
              }}
              onClick={toggleReservations}
            >
              Book an event
            </PrismicLink>
          </div>
        </div>
      </div>
      {/* Flyout menu */}
      <nav
        className={`${!pathHasNavParent ? `no-parent` : ``} z-50 fixed top-0 left-0 w-full md:w-1/2 ${open ? `-translate-x-0` : `-translate-x-full`} transition-transform duration-500 will-change-transform h-full pb-[54px] bg-sand flex flex-col items-center justify-center`}
        data-has-parent={pathHasNavParent}
        {...(open ? { tabIndex: 0 } : { inert: `true` })}
        ref={navRef}
      >
        {/* Close */}
        <button className="absolute top-0 left-0 m-4 mt-[26px] lg:mt-4 -translate-x-[2.5px] -translate-y-[2.13px]" data-toggle onClick={toggleOpen}>
          <span className="sr-only">Close menu</span>
          <svg aria-hidden="true" className="pointer-events-none stroke-current rotate-45" width="25" height="25" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
            <g strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.45">
              <path d="M12 1V24" />
              <path d="M1 12H24" />
            </g>
          </svg>
        </button>
        {/* Show image for active link when device is primary touch */}
        <style>
          {`.no-parent > .nav-img-container > .nav-img-0, ${links?.map((link, i) => `
            .nav-link-${i}.text-red ~ .nav-img-container > .nav-img-${i}`).join(`,`)} {
              opacity: 1;
              transform: scale(1);
              filter: blur(0px);
          }`}
          {`${links?.map((link, i) => `
            .nav-link-${i}.text-red ~ .nav-img-container > .nav-img-${i}`).join(`,`)} {
              z-index: 0;
          }`}
        </style>
        {/* Don’t apply :hover or .focus-visible styles when device is primary touch (causes device to require double-tap) */}
        <style>
          {`${links?.map((link, i) => `
            html.mouse .nav-link-${i}:hover ~ .nav-img-container > .nav-img-${i},
            html.mouse .nav-link-${i}.focus-visible ~ .nav-img-container > .nav-img-${i}`).join(`,`)} {
              opacity: 1;
              transform: scale(1);
              filter: blur(0px);
          }`}
          {`${links?.map((link, i) => `
            html.mouse .nav-link-${i}:hover ~ .nav-img-container > *:not(.nav-img-${i}),
            html.mouse .nav-link-${i}.focus-visible ~ .nav-img-container > *:not(.nav-img-${i})`).join(`,`)} {
              opacity: 0;
          }`}
        </style>
        {/*
          * Separate :focus-visible styles from other declarations because browser that don’t recognize this
          * pseudoselector will throw away the entire stylesheet. Don’t apply :focus-visible styles when
          * device is primary touch (causes device to require double-tap).
          */}
        <style>
          {`${links?.map((link, i) => `
            html.mouse .nav-link-${i}:focus-visible ~ .nav-img-container > .nav-img-${i}`).join(`,`)} {
              opacity: 1;
              transform: scale(1);
              filter: blur(0px);
          }`}
          {`${links?.map((link, i) => `
            html.mouse .nav-link-${i}:focus-visible ~ .nav-img-container > *:not(.nav-img-${i})`).join(`,`)} {
              opacity: 0;
          }`}
        </style>
        {links?.map(({ image, subnav, target, title }, i) => {
          return (
            <PrismicLink
              activeClassName={hover || focus ? `` : `text-red`}
              className={`nav-link-${i} z-10 font-serif font-medium text-2xl mouse:hover:text-red mouse:focus-visible:text-red transition-color duration-300`}
              link={Object.assign({}, target, target?.document)}
              key={title}
              onClick={toggleOpen}
              onMouseEnter={hoverOn}
              onMouseLeave={hoverOff}
              onFocus={focusOn}
              onBlur={focusOff}
              partiallyActive={i > 0}
            >
              {title === `Club` ? (
                <Club className="w-[106px] fill-current" />
              ) : (
                <span dangerouslySetInnerHTML={{ __html: title.replace(`&`, `<em>&amp;</em>`) }} />
              )}
            </PrismicLink>
          )
        })}
        <div className="nav-img-container relative w-[124px] h-[143px] mt-8 overflow-hidden">
          {links?.map(({ image, subnav, target, title }, i) => {
            const imageData = getImage(image?.gatsbyImageData),
              imageComponent = (
                <GatsbyImage
                  alt={imageData?.alt ?? ``}
                  className={`nav-img-${i} z-10 inset-0 scale-[.975] blur-sm opacity-0 motion-safe:transition-all motion-safe:duration-300 motion-safe:will-change-[filter,transform]`}
                  style={{ position: `absolute` }}
                  key={title}
                  image={imageData}
                  sizes="124px"
                />
              )
            return imageComponent
          })}
        </div>
      </nav>
      {/* Flyout footer */}
      <div
        className={`z-50 fixed left-0 bottom-0 flex w-full md:w-1/2 justify-center mb-8 ${open ? `-translate-x-0` : `-translate-x-full`} transition-transform duration-500 will-change-transform`}
        {...(open ? {} : { inert: `true` })}
      >
        {secondaryLinks?.map(({ subnav, target, title }) => {
          return (
            <PrismicLink
              className="mx-2.5 text-xs uppercase mouse:hover:text-red mouse:focus-visible:text-red transition-color duration-300"
              activeClassName="text-red"
              link={Object.assign({}, target, target?.document)}
              key={title}
              onClick={toggleOpen}
            >
              {title}
            </PrismicLink>
          )
        })}
      </div>
    </>
  )
}

export default Navigation
export { Navigation, NavigationQuery }
