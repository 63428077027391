import React, { ReactElement, useContext } from "react"
import { Link, useStaticQuery } from "gatsby"
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"
import TransitionLink from "gatsby-plugin-transition-link"
import linkResolver from "../utilities/linkResolver"
import { useMedia } from "../hooks/useMedia"
import { useLocation } from "@reach/router"
import { NavigationQuery } from "./navigation"
import { LayoutContext } from "./layout"
import windowIfAvailable from "../utilities/windowIfAvailable"

const aggregateSubpaths = (link) => {
  return [
    link?.target && link?.target?.document && linkResolver(Object.assign({}, link.target, link.target.document)),
    ...(link?.subnav?.document?.data?.links?.flatMap(sublink => aggregateSubpaths(sublink)) ?? [])
  ]
}

const getActiveLink = (pathname, links) => {
  const linkPathnames = links.map(link => aggregateSubpaths(link)),
    activeLinkIndex = linkPathnames.findIndex(sublinks =>
      sublinks.indexOf(pathname) > -1
      || sublinks.indexOf(pathname.substring(0, pathname.length - 1)) > -1
    ),
    activeLink = links[activeLinkIndex]
  return activeLink
}

const getSublinks = (link) => {
  return link?.subnav?.document?.data?.links ?? []
}

const getNavDepth = (link) => {
  if(link?.subnav?.document?.data?.links) return 1 + Math.max(...link.subnav.document.data.links.map(link => getNavDepth(link)))
  return 1
}

const getNavHeight = (depth: number) => {
  let navHeight = 16 + 50.39
  if(depth > 1) navHeight += 8 + 13.19 + 8
  // Add bottom padding and bottom border
  navHeight += 10 + 1
  // Third-level menu only counted on mobile b/c it has position absolute on desktop
  return [depth > 2 ? navHeight + 16 + 13.19 : navHeight, navHeight]
}

const PrismicLink = ({ link, children, ...rest }: any): ReactElement => {
  const { link_type, target, type, uid, url, ...linkRest } = link,
    { activeClassName, partiallyActive, ...anchorProps } = rest,
    // { prismicSettings } = useStaticQuery(NavigationQuery),
    staticData = useStaticQuery(NavigationQuery),
    { data: { prismicSettings }, isPreview } = useMergePrismicPreviewData(staticData),
    links = prismicSettings?.data?.primary_nav?.document?.data?.links,
    { pathname } = useLocation(),
    currentTitle = typeof document !== `undefined` ? document.title.replace(` | The Twenty Two`, ``) : undefined,
    currentLink = getActiveLink(pathname, links),
    currentSublinks = getSublinks(currentLink),
    currentNavDepth = getNavDepth(currentLink),
    currentNavHeight = getNavHeight(currentNavDepth),
    nextURL = linkResolver(link),
    nextTitle = link?.link_type === `Document`
      ? link?.data?.title
      : undefined,
    nextLink = getActiveLink(nextURL, links),
    nextSublinks = getSublinks(nextLink),
    nextNavDepth = getNavDepth(nextLink),
    nextNavHeight = getNavHeight(nextNavDepth),
    prefersReducedMotion = useMedia([`(prefers-reduced-motion: reduce)`], [true], false),
    sublinksChanged = currentSublinks !== nextSublinks,
    noSublinks = currentSublinks.length === 0 && nextSublinks.length == 0,
    titleChanged = currentTitle !== nextTitle,
    requiresNavTransition = (sublinksChanged && !noSublinks)
      || ((sublinksChanged || noSublinks) && titleChanged),
    layoutContext = useContext(LayoutContext),
    blankProps = target === `_blank` ? {
      target,
      rel: `noopener`,
    } : {}

  switch(link_type) {
    case `Document`:
      return (
        <TransitionLink
          entry={{
            delay: 0,
            length: 0,
            state: {
              navChanged: requiresNavTransition,
              navHeight: currentNavHeight,
              nextNavHeight,
            },
          }}
          exit={{
            delay: 0,
            length: prefersReducedMotion
              ? 0
              : 0.5,
            state: {
              navChanged: requiresNavTransition,
              navHeight: currentNavHeight,
              nextNavHeight,
            },
          }}
          to={linkResolver(link)}
          {...rest}
        >
          {children}
        </TransitionLink>
      )
    case `Web`:
      // Room bookings
      if(url.indexOf(`bookings.the22.london`) > -1
        || url.indexOf(`reservations.travelclick.com`) > -1) {
        const bookingURL = new URL(url),
          { onClick, ...anchorRest } = anchorProps,
          params = new URLSearchParams(bookingURL.search)
        if(params.get(`roomtypeid`) !== null) {
          if(params.get(`datein`) === null) {
            params.set(`datein`, new Date().toLocaleString(`en-us`, { day: `numeric`, month: `numeric`, year: `numeric` }))
          }
          if(params.get(`dateout`) === null && params.get(`nights`) === null && params.get(`length`) === null) {
            params.set(`nights`, `1`)
          }
          bookingURL.search = params.toString()
        }
        return (
          <a
            href={bookingURL.toString()}
            // onClick={(e) => {
            //   e.preventDefault()
            //   const width = windowIfAvailable()?.screen?.availWidth ?? 550,
            //     height = windowIfAvailable()?.screen?.availHeight ?? 672
            //   window.open(url,`_blank`,`toolbar=no,width=${width},height=${height},top=0,left=0,status=no,scrollbars=no,resize=no,menubar=no`)
            //   typeof onClick === `function` && onClick()
            // }}
            {...anchorRest}
            {...blankProps}
          >
            {children}
          </a>
        )
      // Restaurant bookings
      } else if(url.indexOf(`www.sevenrooms.com`) > -1) {
        const { onClick, ...anchorRest } = anchorProps
        return (
          <a
            href={url}
            // onClick={(e) => {
            //   e.preventDefault()
            //   window.open(url,`_blank`,`toolbar=no,width=550,height=672,top=50,left=50,status=no,scrollbars=no,resize=no,menubar=no`)
            //   typeof onClick === `function` && onClick()
            // }}
            {...anchorRest}
            {...blankProps}
          >
            {children}
          </a>
        )
      // Tripleseat bookings
      } else if(url.indexOf(`/book-an-event`) > -1) {
        const { onClick, ...anchorRest } = anchorProps
        const eventsInquiryData = {
          to: `events@the22.london`,
          subject: `Private Dining & Events Enquiry`,
          body: `Hello,
Thank you so much for your enquiry. If you could kindly complete the requested information below, someone from our events team will get back to you in due course.

Many thanks,
The Events Team at The Twenty Two

Name:
Phone number:
Date of Event:
Timing:
Occasion:
Format:`,
        }, eventsInquiryURL = `mailto:${eventsInquiryData.to}${eventsInquiryData.subject ? `?subject=${encodeURIComponent(eventsInquiryData.subject)}` : ``}${eventsInquiryData.body ? `${eventsInquiryData.subject ? `&` : `?`}body=${encodeURIComponent(eventsInquiryData.body)}` : ``}`
        // const internalURL = url.replace(`http://`, ``).replace(`https://`, ``)
        // const mailtoURL = `mailto:events@the22.london?subject=Private%20Dining%20%26%20Events%20Enquiry`
        return (
          <a
            href={eventsInquiryURL}
            // onClick={(e) => {
              // e.preventDefault()
              // window.open(internalURL,`_blank`,`toolbar=no,width=550,height=672,top=50,left=50,status=no,scrollbars=no,resize=no,menubar=no`)
              // typeof onClick === `function` && onClick()
            // }}
            {...anchorRest}
            {...blankProps}
          >
            {children}
          </a>
        )
      // Subscribe
      } else if(url.indexOf(`://subscribe`) > -1) {
        const { onClick, ...anchorRest } = anchorProps,
          query = useMedia([`(max-width: 639px)`, `(max-width: 767px)`, `(max-width: 1023px)`, `(max-width: 1279px)`, `(max-width: 1535px)`], [true, `sm`, `md`, `lg`, `xl`], `2xl`),
          isMedium = [true, `sm`, `md`].indexOf(query) > -1
        return (
          <button
            onClick={() => {
              !isMedium && layoutContext.setSubscribeBannerState(true)
              if(layoutContext.subscribeBannerButtonRef?.current) {
                layoutContext.subscribeBannerButtonRef.current?.focus()
                if(isMedium) window.scrollTo({
                  top: document.scrollingElement?.scrollHeight ?? 0,
                  behavior: `smooth`,
                })
              }
              if(typeof onClick === `function`) onClick()
            }}
            {...anchorRest}
          >
            {children}
          </button>
        )
      }
      return (
        <a href={url} {...anchorProps} {...blankProps}>{children}</a>
      )
    case `Media`:
      return <Link to={link.url} {...rest}>{children}</Link>
    default:
      return <></>
  }
}

export default PrismicLink
export { PrismicLink, aggregateSubpaths, getActiveLink, getSublinks, getNavDepth, getNavHeight }