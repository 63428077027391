import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"

const FooterQuery = graphql`
  query FooterQuery {
    prismicSettings {
      data {
        address
        telephone
        email
      }
      _previewable
    }
  }
`

const Footer = (props: any) => {
  const staticData = useStaticQuery(FooterQuery),
    { data: { prismicSettings } } = useMergePrismicPreviewData(staticData),
    { data: { address, email, telephone } } = prismicSettings

  return (
    <div className="z-10 fixed w-screen right-0 bottom-0 left-0 flex flex-col md:flex-row items-center md:items-baseline justify-center text-xs uppercase py-2.5 bg-white border-t border-sand">
      <a className="md:mr-2.5 mouse:hover:text-red mouse:focus-visible:text-red transition-colors duration-300" href="https://goo.gl/maps/33PW6tfxERwjjLZb9">{address}</a>
      <div>
        <span className="font-serif font-medium align-baseline">P.</span> <a className="mr-2.5 mouse:hover:text-red mouse:focus-visible:text-red transition-colors duration-300" href={`tel:${telephone}`}>{telephone}</a>
        <span className="font-serif font-medium align-baseline">E.</span> <a className="mouse:hover:text-red mouse:focus-visible:text-red transition-colors duration-300" href={`mailto:${email}`}>{email}</a>
      </div>
    </div>
  )
}

export default Footer
export { Footer }