module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"accessToken":"MC5ZZUczOHhFQUFDQUFpZnp2.77-9CO-_ve-_ve-_vR3vv73vv71877-977-9WAnvv71f77-977-977-9bmtWB--_ve-_ve-_vWUtVntPZDQ","fetchLinks":["page.parent"],"imageImgixParams":{"auto":"format","dpr":1,"fit":"max","q":50},"imagePlaceholderImgixParams":{"w":2,"q":2},"lang":"*","repositoryName":"twentytwo","promptForAccessToken":true,"apiEndpoint":"https://twentytwo.cdn.prismic.io/api/v2","pageSize":100,"toolbar":"new"},
    },{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-P7RLBFB","includeInDevelopment":true,"enableWebVitalsTracking":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Twenty Two","short_name":"The Twenty Two","start_url":"/","background_color":"#B33D26","theme_color":"#FFFFFF","display":"minimal-ui","icon":"src/images/22.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c4189b2a9390acca5d70e7c964bf99f4"},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"layout":"/opt/build/repo/src/components/layout.tsx"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
