import React, { ReactElement } from "react"
import { Carousel } from "./carousel"
import { Split } from "./split"
import { Text } from "./text"

const Slices = ({ body }: { body: any[] }): ReactElement => {
  const firstSliceType = body.length > 0 && body[0]?.slice_type

  return (
    <div className={`
      ${firstSliceType === `carousel`
        ? `mt-0`
        : `mt-12`} md:mt-24`}>
      {body.map((slice, i) => {
        switch(slice.slice_type) {
          case `carousel`:
            return <Carousel key={i} slice={slice} />
          case `split`:
            return <Split key={i} slice={slice} />
          case `text`:
            return <Text key={i} slice={slice} />
          default:
            return null
        }
      })}
    </div>
  )
}

export default Slices
export { Slices }