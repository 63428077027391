import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { ReactElement, useState } from "react"
import { PrismicRichText } from "@prismicio/react"
import { useSwipeable } from "react-swipeable"

const Carousel = ({ slice, ...rest }: any): ReactElement => {
  const [index, setIndex] = useState(0),
    slides = slice.items,
    length = slides.length,
    nextIndex = () => index + 1 >= length ? 0 : index + 1,
    previousIndex = () => index - 1 < 0 ? length - 1 : index - 1,
    captions = slides.map((item, i) => item?.caption),
    captionLengths: number[] = slides.map((item, i) => item?.caption?.text?.length ?? 0),
    hasCaptions = captionLengths.some(length => length !== 0),
    sortedCaptionLengths = captionLengths.sort((a, b) => a - b),
    longestCaptionIndex = captionLengths.indexOf(sortedCaptionLengths[0]),
    longestCaption = captions[longestCaptionIndex]

    const handlers = useSwipeable({
      onSwipedLeft: () => setIndex(nextIndex()),
      onSwipedRight: () => setIndex(previousIndex()),
    });

  return (
    <div className="slice carousel">
      {/* <div className="relative w-screen md:w-auto max-w-500 md:max-w-none aspect-square md:aspect-auto md:h-500 mx-auto"> */}
      <div className="relative w-screen max-w-500 h-[100vw] max-h-500 md:w-auto md:max-w-none mx-auto">
        {slides.map((item, i) => {
          const image = getImage(item?.image)
          return image ? (
            <div
              className={`absolute top-0 left-1/2 transform -translate-x-1/2 max-w-full w-full md:w-[var(--image-width)] h-full flex items-center justify-center ${i === index ? `scale-100 blur-none opacity-100` : `scale-[.975] blur-sm opacity-0`} motion-safe:transition-all motion-safe:duration-300 motion-safe:will-change-[filter,transform] ${length > 1 ? `cursor-pointer` : ``}`}
              {...handlers}
              key={i}
              onClick={() => setIndex(nextIndex())}
              style={{
                "--image-width": `${Math.round(image?.width / image?.height * 500)}px`
              } as React.CSSProperties}
            >
              <GatsbyImage
                alt={item.image.alt ?? ``}
                // className="aspect-square md:aspect-auto"
                className="h-[100vw] max-h-500 pointer-events-none"
                image={image}
                objectFit={`cover`}
                sizes={`(min-width: 768px) ${Math.round(image?.width / image?.height * 500)}px, (min-width: 500px) 500px, 100vw`}
              />
            </div>
          ) : (
            <div
              className={`absolute top-0 left-1/2 transform -translate-x-1/2 max-w-full w-500 h-full flex items-center justify-center bg-gray-light ${i === index ? `scale-100 opacity-100` : `scale-90 opacity-0`} motion-safe:transition-all motion-safe:duration-300 motion-safe:will-change-transform ${length > 1 ? `cursor-pointer` : ``}`}
              key={i}
              onClick={() => setIndex(nextIndex())}
            />
          )
        })}
      </div>
      {hasCaptions && (
        <div className="w-screen md:w-auto max-w-500 md:max-w-none mx-auto">
          <div className="relative">
            <div
              className="caption md:w-[var(--longest-caption-width)] invisible py-2.5 font-sans text-xs"
              style={{
                "--longest-caption-width": `${Math.round(slice.items[longestCaptionIndex].image.width / slice.items[longestCaptionIndex].image.height * 500)}px`
              } as React.CSSProperties}
            >
              <PrismicRichText
                components={{
                  paragraph: ({ children }) => <p className="font-sans text-xs">{children}</p>
                }}
                field={longestCaption?.richText}
              />
            </div>
            {slides.map((item, i) => {
              const image = getImage(item?.image)
              return (
                <div
                  className={`caption absolute top-0 left-0 md:w-[var(--caption-width)] md:left-1/2 md:transform md:-translate-x-1/2 my-2.5 px-2.5 md:px-0 ${i === index ? `opacity-100 delay-150` : `opacity-0`} motion-safe:transition-opacity motion-safe:duration-300`}
                  key={i}
                  style={{
                    "--caption-width": `${Math.round((image?.width ?? 1) / (image?.height ?? 1) * 500)}px`
                  } as React.CSSProperties}
                >
                  <PrismicRichText
                    components={{
                      paragraph: ({ children }) => <p className="font-sans text-xs">{children}</p>
                    }}
                    field={item?.caption?.richText}
                  />
                </div>
              )
            })}
          </div>
        </div>
      )}
      {length > 1 && (
        <div className="text-center">
          {slides.map((item, i) => {
            return (
              <div
                className={`inline-block w-2 h-2 mx-1 rounded-full transform ${i === index ? `scale-100 bg-red` : `scale-[.8] bg-gray-dark`} cursor-pointer motion-safe:transition-all motion-safe:duration-300 motion-safe:will-change-transform`}
                key={i}
                onClick={() => setIndex(i)}
              />
              )
            })}
        </div>
      )}
    </div>
  )
}

export default Carousel
export { Carousel }