import React, { useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"
import {
  WrapPageElementNodeArgs,
  WrapPageElementBrowserArgs
} from "gatsby"
import { useLocation } from "@reach/router"
import { Navigation } from "./navigation"
import { Newsletter } from "./newsletter"
import { Footer } from "./footer"
import { GDPR } from "./gdpr"
import { useEventListener } from "../hooks/useEventListener"
import * as detectIt from "detect-it"

import DiaRegular from "../fonts/Dia-Regular.woff"
import DiaRegularItalic from "../fonts/Dia-RegularItalic.woff"
import AyerPosterMedium from "../fonts/AyerPoster-Medium-Web.woff"
import AyerPosterMediumItalic from "../fonts/AyerPoster-MediumItalic-Web.woff"
import AyerPosterSemibold from "../fonts/AyerPoster-Semibold-Web.woff"
import AyerPosterSemiboldItalic from "../fonts/AyerPoster-SemiboldItalic-Web.woff"

declare global {
  interface Window { _22: any }
}

const LayoutContext = React.createContext<{
  navPortalRef: React.RefObject<HTMLDivElement>,
  subscribeBannerButtonRef: React.RefObject<HTMLButtonElement>,
  subscribeBannerState: boolean,
  setSubscribeBannerState: React.Dispatch<React.SetStateAction<boolean>>,
}>({
  navPortalRef: { current: null },
  subscribeBannerButtonRef: { current: null },
  subscribeBannerState: false,
  setSubscribeBannerState: () => { /* */ },
})

console.log(`Website by Spevack Loeb\nhttps://studio.sl`)

const Layout = ({
  children,
  pageContext,
  ...rest
}: WrapPageElementNodeArgs | WrapPageElementBrowserArgs) => {
  const navPortalRef = useRef<HTMLDivElement>(null),
    subscribeBannerButtonRef = useRef<HTMLButtonElement>(null),
    [subscribeBannerState, setSubscribeBannerState] = useState(false),
    contextValue = { navPortalRef, subscribeBannerButtonRef, subscribeBannerState, setSubscribeBannerState },
    { pathname } = useLocation()

  useEffect(() => {
    window._22 = {
      scrollTop: {
        current: document.documentElement.scrollTop,
      },
    }
  }, [])

  useEventListener(`scroll`, (e) => {
    window._22.scrollTop.current = document.documentElement.scrollTop
  }, typeof window === `undefined` ? {} : window, { passive: true })

  return (
    <>
      <Helmet defaultTitle="The Twenty Two">
        <html className={`antialiased hyphens ${detectIt.primaryInput}`} lang="en" data-pathname={pathname} />
        <meta name="google-site-verification" content="SMUuC5v_XPG29-ONna4RRC8pQXA1ZdZvwdB4W8FKmuM" />
        <meta property="og:type" content="website" />
        <link rel="preload" href={DiaRegular} as="font" type="font/woff" />
        <link rel="preload" href={DiaRegularItalic} as="font" type="font/woff" />
        <link rel="preload" href={AyerPosterMedium} as="font" type="font/woff" />
        <link rel="preload" href={AyerPosterMediumItalic} as="font" type="font/woff" />
        <link rel="preload" href={AyerPosterSemibold} as="font" type="font/woff" />
        <link rel="preload" href={AyerPosterSemiboldItalic} as="font" type="font/woff" />
        <script>{`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window,document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('consent', 'revoke');
          fbq('init', '1154009295343509'); 
          fbq('track', 'PageView');
        `}</script>
        <noscript>{`
          <img height="1" width="1" src="https://www.facebook.com/tr?id=1154009295343509&ev=PageView&noscript=1"/>
        `}</noscript>
      </Helmet>
      <LayoutContext.Provider value={contextValue}>
        <Navigation path={pathname} />
        <div id="nav-portal" className="z-20 fixed lg:hidden top-0 left-0 right-0" ref={navPortalRef} />
        <div id="nav-bg" className="z-10 fixed lg:hidden top-0 left-0 right-0 bg-white border-b border-b-gray-light transition-[height] will-change-[height] duration-500 delay-500" />
        <main className="will-change-contents">
          {children}
        </main>
        <div className={`z-10 fixed inset-0 pointer-events-none max-w-screen overflow-hidden hidden lg:flex justify-end items-center`}>
          <Newsletter hasRef={true} transform={true} />
        </div>
        <GDPR />
        {/* Footer spacer */}
        <div className="h-11" />
        <Footer />
      </LayoutContext.Provider>
    </>
  )
}

export default Layout
export { Layout, LayoutContext }
